import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResumenPrecios } from 'src/app/interfaces/resumenPrecios';
import { DataService } from 'src/app/services/data.service';
import { LoadingController, ToastController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-base-precios-pinos',
  templateUrl: './modal-base-precios-pinos.component.html',
  styleUrls: ['./modal-base-precios-pinos.component.scss'],
})
export class ModalBasePreciosPinosComponent implements OnInit {


  date;
  maxDate = new Date().toISOString();
  file: File;

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])});
  resumenRegistro: ResumenPrecios[];
  botonRegistro = false;
  titulo = 'Subir Archivo';

  constructor(private dataService: DataService,
              private loadingCtrl: LoadingController,
              private toastController: ToastController,
              private modalCtrl: ModalController) { }

  ngOnInit() {}

  onFileChange(event) {
    if (event.target.files.length > 0 ) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }


  subirArchivo() {
    if (!this.myForm.valid) {
      return false;
    }

    this.showLoading('Eliminando registros ...').then( on => {
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);

    this.dataService.deleteVentasPinos(this.date).subscribe(
      res => this.insertarExcel(formData),
      error => console.log(error)
      );
  });
  }

  insertarExcel(formData) {
    this.loadingCtrl.dismiss().then( on => {
      this.showLoading('Insertando datos ...');
      this.dataService.importVentasPinos(formData).subscribe(
        data => {
          this.presentToast('Datos insertados', 'success');
          this.verRegistros();
          this.loadingCtrl.dismiss();
        },
        error => {
          this.presentToast('Error, archivo no se puede procesar', 'warning');
          this.loadingCtrl.dismiss();
        }
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get f() {
    return this.myForm.controls;
  }

  verRegistros() {
    this.botonRegistro = true;
    this.titulo = 'Resumen';
    this.dataService.resumenRegistrosPinos().subscribe((datos: ResumenPrecios[]) => {
      this.resumenRegistro = datos;
    });
  }

  ocultarRegistros() {
    this.titulo = 'Subir Archivo';
    this.resumenRegistro = [];
    this.botonRegistro = false;
  }

  async showLoading(message) {
    const loading = await this.loadingCtrl.create({
      message,
    });

    loading.present();
  }

  async presentToast(message, color) {
    const toast = await this.toastController.create({
      message,
      color,
      duration: 4000
    });

    await toast.present();
  }

  cancelar() {
    this.modalCtrl.dismiss();
  }

}

