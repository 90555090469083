import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { ModalController, LoadingController } from '@ionic/angular';
import { Precio } from '../../interfaces/precios';
import { DataService } from '../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

class Comuna {
  public id: string;
  public name: string;
}

@Component({
  selector: 'app-form-precios',
  templateUrl: './form-precios.component.html',
  styleUrls: ['./form-precios.component.scss'],
})
export class FormPreciosComponent implements OnInit, OnDestroy {

  @Input() usuario;
  comunas: Comuna[];
  comuna: Comuna;
  myForm: FormGroup;
  submitted: boolean;
  productos = [];
  homologos = [];
  subscripcionProductos: Subscription;
  subscripcionHomologos: Subscription;
  segmentoSeleccionado = 'competencia';
  homologoDisable = true;
  ahora = new Date();

  constructor(private modalCtrl: ModalController,
              private dataService: DataService,
              private formBuilder: FormBuilder,
              private loadingCtrl: LoadingController) {

    this.comunas = [
      { id: '8101', name: 'Concepción' },
      { id: '8102', name: 'Coronel' },
      { id: '8103', name: 'Chiguayante' },
      { id: '8104', name: 'Florida' },
      { id: '8105', name: 'Hualqui' },
      { id: '8106', name: 'Lota' },
      { id: '8107', name: 'Penco' },
      { id: '8108', name: 'San Pedro de La Paz' },
      { id: '8109', name: 'Santa Juana' },
      { id: '8110', name: 'Talcahuano' },
      { id: '8111', name: 'Tomé' },
      { id: '8112', name: 'Hualpén' },
      { id: '8201', name: 'Lebu' },
      { id: '8202', name: 'Arauco' },
      { id: '8203', name: 'Cañete' },
      { id: '8204', name: 'Contulmo' },
      { id: '8205', name: 'Curanilahue' },
      { id: '8206', name: 'Los Álamos' },
      { id: '8207', name: 'Tirúa' },
      { id: '8301', name: 'Los Ángeles' },
      { id: '8302', name: 'Antuco' },
      { id: '8303', name: 'Cabrero' },
      { id: '8304', name: 'Laja' },
      { id: '8305', name: 'Mulchén' },
      { id: '8306', name: 'Nacimiento' },
      { id: '8307', name: 'Negrete' },
      { id: '8308', name: 'Quilaco' },
      { id: '8309', name: 'Quilleco' },
      { id: '8310', name: 'San Rosendo' },
      { id: '8311', name: 'Santa Bárbara' },
      { id: '8312', name: 'Tucapel' },
      { id: '8313', name: 'Yumbel' },
      { id: '8314', name: 'Alto Biobío' }

    ];

   }

  ngOnInit() {
    this.ordenarComunas();
    this.myForm = this.formBuilder.group({
      comercio: ['', [Validators.required]],
      comuna: ['', [Validators.required]],
      precio: ['', [Validators.required]],
      cantidad: ['', [Validators.required]],
      producto: ['', [Validators.required]],
      homologo: ['', [Validators.required]],
      tipoPrecio: ['',],
    });
    this.listarProductos();
  }

  ngOnDestroy() {
   this.subscripcionProductos.unsubscribe();
  }

  ordenarComunas() {
    this.comunas.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  listarProductos() {
    this.subscripcionProductos = this.dataService.getProductos().subscribe(productos => {
      this.productos = productos;
    });
  }

  homologoChange(event: {
    component: IonicSelectableComponent;
    value: any;
  }) {

  }

  productoChange(event: {
    component: IonicSelectableComponent;
    value: any;
  }) {
    const producto = this.productos.find(element => element.sku === event.value);
    console.log('producto seleccionado: ', producto);
    this.dataService.getHomologos(producto.id).subscribe( items => {
      this.homologos = items;
    }
    );
    this.homologoDisable = false;
  }

  comunaChange(event: {
    component: IonicSelectableComponent;
    value: any;
  }) {
    console.log('port:', event.value);
  }

  cancelarFormulario() {
    this.modalCtrl.dismiss();
  }

  onSubmit() {
    this.submitted = true;
    if (!this.myForm.valid) {
      console.log('All fields are required.');
      return false;
    } else {
      this.showLoading();
      const {
        comercio,
        comuna,
        cantidad,
        tipoPrecio,
        precio,
        producto,
        homologo,

      } = this.myForm.value;
      const { nombre } = JSON.parse(localStorage.getItem('usuario'));
      const registro = {
        comercio,
        comuna,
        fecha: new Date(),
        marca: '',
        tipoPrecio,
        precio,
        neto: 0,
        costo: 0,
        producto,
        homologo,
        sku: '',
        formato: '',
        cantidad,
        usuario: this.usuario,
        nombreUsuario: nombre ,
      } as Precio;

      const itemHomologo = this.homologos.find(element => element.id === homologo);
      const itemProducto = this.productos.find(element => element.sku === producto);

      // registro.marca = itemHomologo.marca;
      registro.producto = itemProducto.producto;
      registro.homologo =  itemHomologo.producto;
      registro.sku = itemProducto.sku;
      registro.formato = itemProducto.formato;


      if (this.segmentoSeleccionado === 'clienteFinal') {

        // falta asignar si es calculo desde bruto o desde neto !!
        if (registro.tipoPrecio === 'Bruto') {
          registro.neto = precio / 1.19;
          registro.neto = + registro.neto.toFixed(2);
          registro.costo = registro.neto * (1 - 0.3);
          registro.costo = + registro.costo.toFixed(2);
        } else {
          registro.neto = precio;
          registro.costo = registro.neto * (1 - 0.3);
          registro.costo = + registro.costo.toFixed(2);
        }

        // 1 litro x 12
        // 4 litros x 4
        // otros x1 queda el mismo precio neto.

        switch (registro.formato) {
          case '1LT':
            registro.costoFormato = registro.costo * 12;
            break;
          case '4LT':
            registro.costoFormato = registro.costo * 4;
            break;
          default:
            registro.costoFormato = registro.costo;
            break;
        }

        registro.neto = Math.round(registro.neto);
        registro.costo = Math.round(registro.costo);
        registro.costoFormato = Math.round(registro.costoFormato);

      } else {
        registro.neto = precio;
        registro.costo = precio;
        registro.costoFormato = precio;
      }

      this.dataService.registrarPrecio(registro)
                      .then(() => {
                        this.cancelarFormulario();
                        this.loadingCtrl.dismiss();
                      }).catch( (err) => {
                        console.log('error', err);
                        this.loadingCtrl.dismiss();
                      })
                      ;
    }
  }

  segmentChanged(event) {
    this.segmentoSeleccionado = event.target.value;
    if (this.segmentoSeleccionado === 'competencia') {
      console.log('segmento competencia');
      this.myForm.get('tipoPrecio').clearValidators();
    } else {
      this.myForm.get('tipoPrecio').addValidators(Validators.required);
    }
    this.myForm.controls.tipoPrecio.updateValueAndValidity();


  }

  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Procesando ...',
    });
    loading.present();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get errorCtr() {
    return this.myForm.controls;
  }
}
