import { Injectable } from '@angular/core';
import { Auth, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { LoginData } from '../interfaces/login-data';




@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: Auth) {}

  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  isLogedIn() {
    this.auth.onAuthStateChanged(user => {
       if (user) {
            return true;
       }else {
            return false;
       }
    }
    );
  }

  isAuthenticated() {
    return this.auth.currentUser !== null;
  }

  logout() {
    return signOut(this.auth);
  }

  getUserId() {
    return this.auth.currentUser.uid;
  }

  getUser() {
    return this.auth.currentUser;
  }



}
