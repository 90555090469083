/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-comunas',
  templateUrl: './comunas.component.html',
  styleUrls: ['./comunas.component.scss'],
})
export class ComunasComponent implements OnInit {

  comunas = [
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Concepción',
    'Hualpen',
    'Los Ángeles'
  ];
  buscando = false;
  mensaje = '';
  comunasFiltradas = [];

  constructor(private modalCtrl: ModalController) {
   }

  ngOnInit() {
    this.comunasFiltradas = [...this.comunas];
  }

  clickComuna(comuna) {
    this.modalCtrl.dismiss(comuna);
  }

  buscarComuna(event) {
    this.mensaje = '';
    this.buscando = true;

    const busqueda = event.detail.value.toLowerCase()
                                       .replace('á','a')
                                       .replace('é','e')
                                       .replace('í','i')
                                       .replace('ó','o')
                                       .replace('ú','u');
    if (!busqueda) {
      this.comunasFiltradas = [...this.comunas];
      this.buscando = false;
      return false;
    }

    this.comunasFiltradas = this.comunas.filter(comuna => comuna.toLowerCase()
      .replace('á', 'a')
      .replace('é', 'e')
      .replace('í', 'i')
      .replace('ó', 'o')
      .replace('ú', 'u').search(busqueda) !== -1);

    if (this.comunasFiltradas.length === 0) {
      this.mensaje = 'No se encontraron coincidencias';
    }
    this.buscando = false;
  }


}
