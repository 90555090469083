import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Firestore,
         collectionData,
         collection,
         doc,
         docData,
         addDoc,
         deleteDoc,
         updateDoc,
         setDoc,
         where,
         query} from '@angular/fire/firestore';
import { limit, orderBy } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Homologo,
         Precio,
         Producto,
         Reporte,
         ResumenPrecios,
         Usuario
        } from '../interfaces';



@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private firestore: Firestore,
              private http: HttpClient) { }

  getReportesUsuario(id: any): Observable<Reporte[]> {
    const reportesRef = collection(this.firestore, `usuarios/${id}/reportes`);
    return collectionData(reportesRef, {idField: 'id'}) as Observable<Reporte[]>;
  }

  addReporteUsuario(idUsuario: any ,reporte: Reporte) {
    const reportesRef = collection(this.firestore, `usuarios/${idUsuario}/reportes`);
    return addDoc(reportesRef, reporte);
  }

  updateReporteUsuario(idUsuario: any, reporte: Reporte) {
    const reporteRef = doc(this.firestore, `usuarios/${idUsuario}/reportes/${reporte.id}`);
    return updateDoc(reporteRef, { nombre: reporte.nombre, descripcion: reporte.descripcion, url: reporte.url });
  }

  deleteReporteUsuario(idUsuario: any, idReporte) {
    const reportesRef = doc(this.firestore, `usuarios/${idUsuario}/reportes/${idReporte}`);
    return deleteDoc(reportesRef);
  }

  getReportes(): Observable<Reporte[]> {
    const reportesRef = collection(this.firestore, 'reportes');
    return collectionData(reportesRef, { idField: 'id' }) as Observable<Reporte[]>;
  }

  getUsuarios(): Observable<Usuario[]> {
    const usuariosRef = collection(this.firestore, 'usuarios');
    return collectionData(usuariosRef, { idField: 'id' }) as Observable<Usuario[]>;
  }

  getUserInfoById(id): Observable<Usuario> {
    const usuarioRef = doc(this.firestore, `usuarios/${id}`);
    return docData(usuarioRef, { idField: 'id' }) as Observable<Usuario>;
  }

  /* CRUD base precios */

  getBasePreciosDiaria(usuario: any): Observable<Precio[]> {
    const fechaHoy = new Date();
    fechaHoy.setHours(0,0,0,0);
    const preciosRef = collection(this.firestore, 'base_precios');
    const filtro = query(preciosRef,
                         where('usuario', '==', usuario),
                         where('fecha', '>', fechaHoy ),
                         orderBy('fecha', 'desc'));
    return collectionData(filtro, { idField: 'id' }) as Observable<Precio[]>;
  }

  getBasePrecios(): Observable<Precio[]> {
    const hasta = new Date();
    const desde = new Date();
    desde.setDate(desde.getDate() - 30);
    desde.setHours(0, 0, 0, 0);
    const preciosRef = collection(this.firestore, 'base_precios');
    const filtro = query(preciosRef,
      where('fecha', '>', desde),
      where('fecha', '<', hasta),
      orderBy('fecha', 'desc'));
    return collectionData(filtro, { idField: 'id' }) as Observable<Precio[]>;
  }

  registrarPrecio(registro: Precio) {
    const preciosRef = collection(this.firestore, 'base_precios');
    return addDoc(preciosRef, registro);
  }

  getProductos(): Observable<Producto[]> {
    const productosRef = collection(this.firestore, 'productos');
    const filtro = query(productosRef,
                   orderBy('producto'));
    return collectionData(filtro, { idField: 'id' }) as Observable<Producto[]>;
  }

  getHomologos(idProducto: string): Observable<Homologo[]> {
    console.log(idProducto);
    const homologosRef = collection(this.firestore, `productos/${idProducto}/homologos`);
    const filtro = query(homologosRef,
      orderBy('producto'));
    return collectionData(filtro, { idField: 'id' }) as Observable<Homologo[]>;
  }

  addProducto(producto: Producto) {
    const productosRef = collection(this.firestore, `productos`);
    return setDoc(doc(productosRef, producto.sku), producto);
  }

  addHomologo(homologo: any) {
    const { producto, idCopec } = homologo;
    const homologosRef = collection(this.firestore, `productos/${idCopec}/homologos`);
    return addDoc(homologosRef, { producto });
  }

  /* FIN CRUD base precios */

  /* CRUD API PINOS */

  getVentasPinos() {
    return this.http.get('https://api.pinosdemontemar.cl/api/ventas?api_key=key_cur_prod_asdR41Erf2HKpO1Iu3gFfd53ERCDf56tsrR');
  }

  deleteVentasPinos(fecha) {
    return this.http.delete(`https://api.pinosdemontemar.cl/api/ventas/${fecha}?api_key=key_cur_prod_asdR41Erf2HKpO1Iu3gFfd53ERCDf56tsrR`);
  }

  importVentasPinos(file) {
    return this.http.post(`https://api.pinosdemontemar.cl/api/ventas?api_key=key_cur_prod_asdR41Erf2HKpO1Iu3gFfd53ERCDf56tsrR`, file);
  }

  resumenRegistrosPinos(): Observable<ResumenPrecios[]> {
    return this.http
                .get<ResumenPrecios[]>
                ('https://api.pinosdemontemar.cl/api/resumenVentas?api_key=key_cur_prod_asdR41Erf2HKpO1Iu3gFfd53ERCDf56tsrR')
                .pipe(
                  map( resp => resp)
                );
  }

  /* FIN CRUD API PINOS */

}
