import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  admin = false;
  constructor(private menuCtrl: MenuController,
              private dataService: DataService,
              private auth: Auth,
              private router: Router ) {

  }

  ngOnInit() {
    console.log('inicia menu');

    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.dataService.getUserInfoById(user.uid).subscribe((res: any) => {
          if (res.rol === 'admin') {
            this.admin = true;
          }
        }
        );
      } else {
        this.router.navigate(['/login']);
      }
    }
    );


    /* if (localStorage.getItem('usuario')) {
      const { rol } = JSON.parse(localStorage.getItem('usuario'));
      if (rol === 'admin') {
        this.admin = true;
      }
    } */
  }

  toggleMenu() {
    this.menuCtrl.toggle();
  }

}
