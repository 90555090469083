import { Component, OnInit, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Reporte } from 'src/app/interfaces/reporte';
import { Usuario } from 'src/app/interfaces/usuario';


@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {

  @Input() reportes: Reporte[];
  usuario: Usuario;

  constructor(private popOverCtrl: PopoverController
  ) { }

  ngOnInit() {

  }

  closeModal() {
    this.popOverCtrl.dismiss();
  }

  verInforme(url: string) {
    this.popOverCtrl.dismiss(url);
  }

}
