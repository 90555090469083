import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MenuController } from '@ionic/angular';
import { Auth } from '@angular/fire/auth';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  subUserinfo: any;

  constructor(private authService: AuthService,
              private router: Router,
              private auth: Auth,
              private dataService: DataService
              ) {
  }


  ngOnInit() {
    console.log('inicia app');
    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.subUserinfo = this.dataService.getUserInfoById(user.uid).subscribe((res: any) => {
          localStorage.setItem('usuario', JSON.stringify(res));
        });
      } else {
        this.router.navigate(['/login']);
      }
    }
    );
  }

  logOut() {
    this.authService.logout()
      .then(() => {
        localStorage.clear();
        this.router.navigate(['/']);
      })
      .catch((e) => console.log(e.message));
  }



}
