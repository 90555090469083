import { Component, Input, OnInit } from '@angular/core';
import { Usuario } from 'src/app/interfaces/usuario';
import { DataService } from 'src/app/services/data.service';
import { Reporte } from '../../interfaces/reporte';
import { AlertController, ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-modal-reportes-usuario',
  templateUrl: './modal-reportes-usuario.component.html',
  styleUrls: ['./modal-reportes-usuario.component.scss'],
})
export class ModalReportesUsuarioComponent implements OnInit {

  @Input() usuario: Usuario;
  reportes = [];
  reporte: Reporte = {
    nombre: '',
    descripcion: '',
    url: ''
  } as Reporte;
  modoEdicion = false;

  loaded = false;

  reporteSubs: any;

  constructor(private dataService: DataService,
              private modalCtrl: ModalController,
              private toastCtrl: ToastController,
              private alertCtrl: AlertController) {

  }

  ngOnInit() {
    this.reporteSubs = this.dataService.getReportesUsuario(this.usuario.id).subscribe((res) => {
      this.reportes = res;
      this.loaded = true;
    });
  }

  ionViewWillLeave() {
    this.reporteSubs.unsubscribe();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  nuevoReporte() {
    this.reporte = {
      nombre: '',
      descripcion: '',
      url: ''
    } as Reporte;
    this.modoEdicion = true;
  }


  editarReporte(reporte) {
    this.reporte = reporte;
    this.modoEdicion = true;
  }

  eliminarReporte() {
    this.dataService.deleteReporteUsuario(this.usuario.id, this.reporte.id).then(() => {
      this.presentToast('Reporte eliminado', 'success');
      this.reporte = {} as Reporte;
      this.modoEdicion = false;
    });
  }

  guardarReporte() {
    const { nombre, descripcion, url} = this.reporte;
    if (nombre === '' || descripcion === '' || url === '') {
      this.presentToast('Todos los campos son obligatorios', 'danger');
      return false;
    }

    if (this.reporte.id) {
      this.dataService.updateReporteUsuario(this.usuario.id, this.reporte).then(() => {
        this.modoEdicion = false;
        this.reporte = {} as Reporte;
        this.presentToast('Reporte actualizado', 'success');
      });
    }else{
      this.dataService.addReporteUsuario(this.usuario.id, this.reporte).then(() => {
        this.modoEdicion = false;
        this.reporte = {} as Reporte;
        this.presentToast('Reporte Añadido', 'success');
      }).catch((error) => {
      });
    }
  }

  cancelar() {
    this.modoEdicion = false;
    this.reporte = {} as Reporte;
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      color
    });
    toast.present();
  }

  async eliminaReporte() {
    const alert = await this.alertCtrl.create({
      cssClass: 'buttonCss',
      header: 'Confirma!',
      message: '¿Estás seguro de eliminar el reporte?' + this.reporte.nombre,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          id: 'cancel-button',
          handler: (blah) => {
          }
        }, {
          text: 'Eliminar',
          id: 'confirm-button',
          handler: () => {
            this.eliminarReporte();
          }
        }
      ]
    });

    await alert.present();
  }

}
