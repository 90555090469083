import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { IonicModule } from '@ionic/angular';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { RouterModule } from '@angular/router';
import { ModalReportesUsuarioComponent } from './modal-reportes-usuario/modal-reportes-usuario.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormPreciosComponent } from './form-precios/form-precios.component';
import { ComunasComponent } from './comunas/comunas.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { ModalBasePreciosPinosComponent } from './modal-base-precios-pinos/modal-base-precios-pinos.component';

@NgModule({
  declarations: [
    MenuComponent,
    ModalInfoComponent,
    ModalReportesUsuarioComponent,
    FormPreciosComponent,
    ComunasComponent,
    ModalBasePreciosPinosComponent
  ],
  exports: [
    MenuComponent,
    ModalInfoComponent,
    ModalReportesUsuarioComponent,
    FormPreciosComponent,
    ComunasComponent,
    ModalBasePreciosPinosComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    IonicSelectableModule,
    ReactiveFormsModule,
  ]
})
export class ComponentsModule { }
